import { propTypes } from '@sanity/block-content-to-react'
import { Link } from 'gatsby'
import React from 'react'

import * as styles from './sub-nav.module.css'
import { responsiveTitle3 } from './typography.module.css'

function SubNavigations({ items, current, collapsed }) {
  return (
    items &&
    items.length > 0 && (
      <nav className={styles.nav} style={{ direction: 'rtl', textAlign: 'right' }}>
        <input
          id="nav__toggle"
          name="nav__toggle"
          className={styles.nav__toggle}
          type="checkbox"
          checked={!collapsed}
        />
        <label htmlFor="nav__toggle" className={styles.nav__current}>
          <span> إختيار التصنيف: </span> <strong>{current ? current : 'كل المواضيع'}</strong>
        </label>
        <div className={styles.nav__list}>
          <ul>
            {items.map(({ name, name_ar, internal }) => (
              <Link
                key={name}
                to={(internal || {}).type === 'SanityTag' ? `/tags/${name}` : `/${name}`}
              >
                <li className={styles.nav__item}>{name_ar}</li>
              </Link>
            ))}
          </ul>
        </div>
      </nav>
    )
  )
}

export default SubNavigations
