import React from 'react'
import { graphql, Link } from 'gatsby'
import { mapEdgesToNodes } from '../lib/helpers'
import { BlogPostPreviewGrid } from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import SubNavigations from '../components/sub-nav'
import Breadcrumb from '../components/layout/breadcrumb'

import { responsiveTitle1 } from '../components/typography.module.css'
import {
  pagination,
  pagination__first,
  pagination__prev,
  pagination__next,
  pagination__last,
} from './page.module.css'

export const query = graphql`
  query PageQuery($categories: String!, $skip: Int!, $limit: Int!) {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
      filter: { category: { name: { regex: $categories } } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          category {
            name
            name_ar
          }
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const CategoryPage = props => {
  const { data, errors, pageContext, path = '' } = props
  const {
    subNav = [],
    subNavCollapsed = true,
    showExcerpt,
    title = '',
    parentName = '',
    parentName_ar = '',
    slug,
    numPages,
    currentPage,
  } = pageContext ? pageContext : []
  const _currentNavItemName = path.split('/').pop()
  const currentNavItemIndex = subNav.findIndex(item => item.name === _currentNavItemName)
  const currentNavItem =
    subNav.length > 0 && subNav[currentNavItemIndex]
      ? subNav[currentNavItemIndex].name_ar
      : null

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  let navItemStack = []
  if (parentName_ar) navItemStack.push({ name: parentName_ar, slug: parentName.current })
  navItemStack.push({ name: title, slug })

  return (
    // console.log({ currentPage, numPages }) || (
    <Layout>
      {/* <SEO title={`${title}${currentPage > 1 ? ` (${currentPage})` : ''}`} /> */}
      <Container>
        <Breadcrumb currentNavItems={navItemStack} />
        {/* <h1 className={responsiveTitle1}>Archive</h1> */}
        <SubNavigations collapsed={subNavCollapsed} items={subNav} current={currentNavItem} />
        {postNodes && postNodes.length > 0 && (
          <BlogPostPreviewGrid showExcerpt={showExcerpt} nodes={postNodes} />
        )}
        <div className={pagination}>
          {currentPage > 2 && (
            <Link className={pagination__first} to={`/${slug}`}>{`1..`}</Link>
          )}
          {currentPage !== 1 && (
            <Link
              className={pagination__prev}
              to={`/${slug}/${currentPage === 2 ? '' : currentPage - 1}`}
            >
              السابق
            </Link>
          )}
          {currentPage < numPages && (
            <Link className={pagination__next} to={`/${slug}/${currentPage + 1}`}>
              التالي
            </Link>
          )}
          {currentPage < numPages - 2 && (
            <Link
              className={pagination__last}
              to={`/${slug}/${numPages}`}
            >{`..${numPages}`}</Link>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default CategoryPage

export const Head = ({ location, pageContext }) => (
  <SEO
    title={`${pageContext?.title}${
      pageContext?.currentPage > 1 ? ` (${pageContext?.currentPage})` : ''
    }`}
    pathname={location.pathname}
  ></SEO>
)
